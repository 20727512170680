import "./style.scss";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import {
  changeColorCase,
  prefixWithDollarSign,
  renderBoldText,
} from "@utils/helper";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";
import { TextStyle } from "@able/react";
import QuantityButton from "../components/quantity-button";
import { useEffect, useState } from "react";
import StockStatus from "../components/stock-status";

const CONSTANTS = SELECT_DEVICES.TABLE_COLUMN_HEADERS;

const columns = [
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.MODEL),
    resizable: false,
    minWidth: 337,
    maxWidth: 337,
    Cell: (rowData) => {
      return (
        <div>
          <TextStyle alias="Tag">
            {rowData?.original?.category?.toUpperCase()}
          </TextStyle>
          <TextStyle alias="HeadingD">{rowData?.original?.name}</TextStyle>
          <TextStyle alias="Tag">{`SKU:${rowData?.original?.sku}`}</TextStyle>
        </div>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.STORAGE),
    resizable: false,
    minWidth: 88,
    maxWidth: 88,
    Cell: (rowData) => {
      return <TextStyle alias="Base">--</TextStyle>;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.COLOUR),
    resizable: false,
    minWidth: 160,
    maxWidth: 160,
    Cell: (rowData) => {
      return (
        <TextStyle alias="Base">
          {changeColorCase(rowData?.original?.color)}
        </TextStyle>
      );
    },
  },

  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.AVAILABILITY),
    resizable: false,
    minWidth: 185,
    maxWidth: 185,
    Cell: (rowData) => {
      return <StockStatus product={rowData?.original} showMessage={true} />;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-right",
    Header: renderBoldText(CONSTANTS.OUTRIGHT_CHARGE),
    resizable: false,
    minWidth: 260,
    maxWidth: 260,
    Cell: (rowData) => {
      return (
        <div className="outright-charge">
          <TextStyle alias="Label">
            {prefixWithDollarSign(rowData?.original?.price)}
          </TextStyle>
          <TextStyle alias="FinePrint">
            {rowData?.original?.minMonthlyCharge
              ? `${SELECT_DEVICES.DEVICE_PAYMENT_MSG1} ${prefixWithDollarSign(
                  rowData?.original?.minMonthlyCharge
                )} ${SELECT_DEVICES.DEVICE_PAYMENT_MSG2}`
              : `${SELECT_DEVICES.OUTRIGHT}`}
          </TextStyle>
        </div>
      );
    },
  },
];

const TableView = ({ devices }) => {
  const [paginationDetails, setPaginationDetails] = useState({
    data: [],
    pageSize: 10,
    totalRecordLength: 0,
    pages: 0,
    page: 1,
    recordsPerApi: 10,
  });

  const generateTableData = (
    pageNum,
    sorted = [{}],
    pageSizeVal = 0,
    event = ""
  ) => {
    const totalDevices = devices?.length;

    if (totalDevices) {
      let pSize = paginationDetails.pageSize;
      const resultData = [];
      let lastIndex, startIndex;

      pSize = pageSizeVal > 0 ? pageSizeVal : pSize;

      lastIndex = pageNum * pSize;

      if (lastIndex > totalDevices) {
        lastIndex = totalDevices;
      }

      startIndex = lastIndex - pSize;

      if (pSize * pageNum > totalDevices) {
        startIndex = pSize * (pageNum - 1);
      }

      if (pageNum === 1 && pSize > totalDevices) {
        lastIndex = totalDevices;
        startIndex = 0;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        resultData.push(devices[i]);
      }

      setPaginationDetails({
        data: resultData,
        pageSize: pSize,
        totalRecordLength: totalDevices,
        pages: Math.ceil(totalDevices / pSize),
        page: pageNum,
        recordsPerApi: pSize,
      });
    }
  };

  useEffect(() => {
    generateTableData(1, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return (
    <div className="select-devices-table">
      <AdvanceTableV3
        columns={columns}
        resizable={true}
        data={paginationDetails.data}
        totalRecordLength={paginationDetails.totalRecordLength}
        pages={paginationDetails.pages}
        page={paginationDetails.page}
        pageSize={paginationDetails.pageSize}
        defaultPageSize={paginationDetails.pageSize}
        className="td-align-left"
        pageHeaderClassName={"header"}
        isStickyHeader={true}
        sendApi={generateTableData}
        action={{
          sticky: true,
          column: {
            headerClassName: "table-align-left tl-tableV3-last-sticky",
            className: "td-align-left",
            Header: renderBoldText(CONSTANTS.QUANTITY),
            resizable: false,
            minWidth: 178,
            maxWidth: 178,
            style: { height: "84px" },
            Cell: (rowData) => {
              return <QuantityButton device={rowData?.original} />;
            },
          },
        }}
      />
    </div>
  );
};
export default TableView;
