/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useState } from "react";
import { TabList, Tab } from "telstra-ui/components/tab/Tabs";
import PlansTableView from "./components/table-view";
import { MessageSection } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { useAppSelector } from "@state/hooks";

const PlansBySubcategory = ({ plans }) => {
  const plansBySubCategory = new Map();
  const subCategories = [];

  plans.forEach((plan) => {
    const subCategory = plan.subCategory;
    if (plansBySubCategory.has(subCategory)) {
      plansBySubCategory.get(subCategory).push(plan);
    } else {
      subCategories.push(subCategory);
      plansBySubCategory.set(subCategory, [plan]);
    }
  });

  const [activeSubCategory, setActiveSubCategory] = useState(subCategories[0]);

  useEffect(() => {
    if (!plansBySubCategory.get(activeSubCategory)) {
      setActiveSubCategory(subCategories[0]);
    }
  }, [plans.length]);

  const [serviceError, setServiceError] = useState("");

  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );

  const setServiceErrorFn = (error) => {
    setServiceError(error);
    if (!selectedPlans.length) {
      window.scrollTo({
        top: 700,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {serviceError && !selectedPlans.length && (
        <MessageSection
          description={serviceError}
          variant="Error"
          developmentUrl={ableDevelopmentUrl}
        />
      )}
      <TabList className="subcategory-tabs">
        {subCategories.map((subCategory) => {
          return (
            <Tab
              key={subCategory}
              id={subCategory}
              active={activeSubCategory === subCategory}
              onClick={(id) => setActiveSubCategory(id)}
            >
              {`${subCategory} (${plansBySubCategory.get(subCategory).length})`}
            </Tab>
          );
        })}
      </TabList>
      {plansBySubCategory.get(activeSubCategory) && (
        <PlansTableView
          plans={plansBySubCategory.get(activeSubCategory)}
          setServiceError={setServiceErrorFn}
          key={activeSubCategory}
        />
      )}
    </>
  );
};

export default PlansBySubcategory;
