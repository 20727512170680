import "./style.scss";
import { Column, Grid, IconButton, TextStyle } from "@able/react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";
import {
  ableDevelopmentUrl,
  changeColorCase,
  prefixWithDollarSign,
  renderBoldText,
  useIntersectionObserver,
} from "@utils/helper";
import { deleteSelectedDevice } from "@state/select-devices-slice";
import { useRef } from "react";
import ViewItemsFloater from "../view-items-floater";
import StockStatus from "../stock-status";

const CONSTANTS = SELECT_DEVICES.TABLE_COLUMN_HEADERS;

const columns = [
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.MODEL),
    resizable: false,
    minWidth: 337,
    maxWidth: 337,
    Cell: (rowData) => {
      return (
        <div>
          <TextStyle alias="Tag">
            {rowData?.original?.category?.toUpperCase()}
          </TextStyle>
          <TextStyle alias="HeadingD">{rowData?.original?.name}</TextStyle>
        </div>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.STORAGE),
    resizable: false,
    minWidth: 88,
    maxWidth: 88,
    Cell: (rowData) => {
      return <TextStyle alias="Base">--</TextStyle>;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.COLOUR),
    resizable: false,
    minWidth: 160,
    maxWidth: 160,
    Cell: (rowData) => {
      return (
        <TextStyle alias="Base">
          {changeColorCase(rowData?.original?.color)}
        </TextStyle>
      );
    },
  },

  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.AVAILABILITY),
    resizable: false,
    minWidth: 130,
    maxWidth: 130,
    Cell: (rowData) => {
      return <StockStatus product={rowData?.original} showMessage={false} />;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-right ",
    Header: renderBoldText(CONSTANTS.UNIT_CHARGE),
    resizable: false,
    minWidth: 220,
    maxWidth: 220,
    Cell: (rowData) => {
      return (
        <TextStyle alias="Label">
          {prefixWithDollarSign(rowData?.original?.price)}
        </TextStyle>
      );
    },
  },
  {
    headerClassName: "table-align-left quantity-header",
    className: "td-align-center",
    Header: renderBoldText(CONSTANTS.QUANTITY),
    resizable: false,
    minWidth: 116,
    maxWidth: 116,
    Cell: (rowData) => {
      return <TextStyle alias="Base">{rowData?.original?.quantity}</TextStyle>;
    },
  },
];

const SelectedDevicesTable = () => {
  const dispatch = useAppDispatch();

  const { selectedDevices } = useAppSelector((state) => state.selectDevices);

  const tableRef = useRef(null);
  const isTableVisible = useIntersectionObserver(tableRef, { threshold: 0 });

  return (
    <div className="selected-devices-table" ref={tableRef}>
      <Grid>
        <Column>
          <AdvanceTableV3
            data={selectedDevices}
            columns={columns}
            resizable={true}
            className="td-align-left"
            totalRecordLength={selectedDevices.length}
            pages={1}
            pageSize={selectedDevices.length}
            defaultPageSize={10}
            page={1}
            pageHeaderClassName={"header"}
            isStickyHeader={true}
            sendApi={() => null}
            action={{
              sticky: true,
              column: {
                headerClassName: "table-align-left",
                className: "td-align-left ",
                Header: renderBoldText(CONSTANTS.REMOVE),
                resizable: false,
                minWidth: 95,
                maxWidth: 95,
                Cell: (rowData) => {
                  return (
                    <IconButton
                      variant="Destructive"
                      icon="Calendar"
                      size="Big"
                      developmentUrl={ableDevelopmentUrl}
                      events={{
                        onClick: () => {
                          dispatch(deleteSelectedDevice(rowData.original.id));
                        },
                      }}
                    />
                  );
                },
              },
            }}
          />
        </Column>
      </Grid>
      {!isTableVisible && <ViewItemsFloater />}
    </div>
  );
};
export default SelectedDevicesTable;
