/* eslint-disable react-hooks/exhaustive-deps */
import { ableDevelopmentUrl, renderBoldText } from "@utils/helper";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import {
  ModalDialogConfirmationDestructive,
  Icon,
  IconButton,
} from "@able/react";
import SelectedPlansCount from "@pages/new-plan-order/steps/components/selected-items-count";
import PlanCheckbox from "@pages/new-plan-order/steps/components/plan-checkbox";
import Selections from "@pages/new-plan-order/steps/components/selections";
import { BARRING_ADDONS_TABLE } from "@pages/new-plan-order/constant";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleAll } from "@state/common-slice";
import { deletePlan, setDeltaPlans } from "@state/cart-slice";
import { createDeltaItem } from "@services/templates/cart";

const PlansTableView = ({ plans, setServiceError }) => {
  useEffect(() => {
    const ids = plans.map((plan) => plan.id);
    dispatch(toggleAll(ids));
  }, [plans.length]);

  const dispatch = useAppDispatch();
  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );

  const [state, setState] = useState({
    data: [],
    pageSize: 5,
    totalRecordLength: 0,
    pages: 0,
    page: 0,
    recordsPerApi: 5,
  });

  useEffect(() => {
    sendApi(1, []);
    if (selectedPlans.length > 0) {
      setServiceError("");
    }
  }, [plans]);

  const handleToggleAll = () => {
    if (selectedPlans.length === plans.length) {
      dispatch(toggleAll([]));
    } else {
      const planIds = [];
      plans.forEach((plan) => planIds.push(plan.id));
      dispatch(toggleAll(planIds));
    }
  };

  const sendApi = (pageNum, sorted = [{}], pageSizeVal = 0, event = "") => {
    const plansLength = plans.length;
    if (plansLength) {
      let pSize = state.pageSize;
      const resultData = [];
      let lastIndex, startIndex;

      if (pageSizeVal > 0) {
        pSize = pageSizeVal;
      }

      pSize = pageSizeVal > 0 ? pageSizeVal : pSize;

      lastIndex = pageNum * pSize;

      if (lastIndex > plansLength) {
        lastIndex = plansLength;
      }

      startIndex = lastIndex - pSize;

      if (pSize * pageNum > plansLength) {
        startIndex = pSize * (pageNum - 1);
      }

      if (pageNum === 1 && pSize > plansLength) {
        lastIndex = plansLength;
        startIndex = 0;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        resultData.push(plans[i]);
      }

      setState({
        data: resultData,
        pageSize: pSize,
        totalRecordLength: plansLength,
        pages: Math.ceil(plansLength / pSize),
        page: pageNum,
        recordsPerApi: pSize,
      });
    }
  };

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-left",
      Header: (
        <Checkbox
          id="toggle-all"
          checked={plans.length && selectedPlans.length === plans.length}
          label={renderBoldText(BARRING_ADDONS_TABLE.PLAN)}
          onChange={() => handleToggleAll()}
        />
      ),
      resizable: false,
      width: 550,
      pinned: true,
      Cell: (rowData) => <PlanCheckbox item={rowData.original} />,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(BARRING_ADDONS_TABLE.BARRING),
      resizable: false,
      width: 350,
      Cell: (rowData) => (
        <Selections plan={rowData.original} field="barrings" />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(BARRING_ADDONS_TABLE.ADD_ONS),
      resizable: false,
      width: 350,
      Cell: (rowData) => <Selections plan={rowData.original} field="addOns" />,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(BARRING_ADDONS_TABLE.CLONE),
      resizable: false,
      width: 80,
      Cell: () => (
        <Icon icon="Copy" size="32" developmentUrl={ableDevelopmentUrl} />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(BARRING_ADDONS_TABLE.REMOVE),
      minWidth: 80,
      resizable: false,
      Cell: (rowData) => (
        <ModalDialogConfirmationDestructive
          developmentUrl={ableDevelopmentUrl}
          title={BARRING_ADDONS_TABLE.REMOVE_PLAN.TITLE}
          description={BARRING_ADDONS_TABLE.REMOVE_PLAN.DESCRIPTION}
          stackButtonOnVXS={false}
          confirmButtonLabel={BARRING_ADDONS_TABLE.REMOVE_PLAN.LABEL}
          confirmButtonEvents={{
            onClick: () => {
              dispatch(deletePlan(rowData.original.id));
              dispatch(
                setDeltaPlans(createDeltaItem(rowData.original, "remove"))
              );
            },
          }}
          cancelButtonLabel={BARRING_ADDONS_TABLE.REMOVE_PLAN.CANCEL}
          cancelButtonEvents={{}}
        >
          <IconButton
            icon="Calendar"
            screenReaderContent={BARRING_ADDONS_TABLE.REMOVE_PLAN.LABEL}
            variant="Destructive"
            developmentUrl={ableDevelopmentUrl}
            size="Big"
          />
        </ModalDialogConfirmationDestructive>
      ),
    },
  ];

  return (
    <>
      <SelectedPlansCount />
      <div className="config-barring-addons-table">
        <AdvanceTableV3
          columns={columnData}
          data={state.data}
          totalRecordLength={state.totalRecordLength}
          pages={state.pages}
          pageSize={state.pageSize}
          defaultPageSize={state.pageSize}
          sendApi={sendApi}
          page={state.page}
        />
      </div>
    </>
  );
};

export default PlansTableView;
