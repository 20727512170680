export const NEW_PLAN_ORDER = {
  PAGE_TITLE: "New plan order",
  SELECT_PLAN_TAGLINE: "Select plan/s",
  BARRING_ADDONS_TAGLINE: "Configure plans: Barring & add-ons",
  SIM_CONFIGURATION_TAGLINE: "Configure plans: SIM configuration",
  HEADING: "Select plan",
  FILTERS: "Filters",
  UNLIMITED_CALLS_INCLUSION: "Unlimited calls and texts in Australia",
  PLAN_SHARING: "Shared data pool",
  PLAN_PRICE_TEXT: "ex GST",
  PLAN_PER_MONTH: "/mth",
  SIM_CONFIGURATION_ASSISTIVE_TEXT:
    "You can update SIM serial numbers and usernames via the table below or the template. To search for custom phone numbers, select the plans you want to update. Phone numbers will stay reserved for up to 29 days. After this time, they will return to the number reservation pool.",
  CONFIGURE_PLAN_BARRING_ADD_ONS: {
    TITLE: "Configure plans: Barring & add-ons",
    BARRING: {
      SECTION_TITLE: "Barring (optional)",
      ASSISTIVE_TEXT:
        "The following network features will come enabled with this plan. Select any that need to be switched off.",
    },
    ALL_CATEGORIES: ["mobile", "broadband", "enterprise"],
    ALL_SUBCATEGORIES: ["essential", "enhanced", "epic", "executive"],
    CATEGORY_LABELS: {
      mobile: "Mobile",
      broadband: "Mobile Broadband",
      enterprise: "Enterprise Wireless - Shared",
    },
    SUBCATEGORY_LABELS: {
      essential: "Essential",
      enhanced: "Enhanced",
      epic: "Epic",
      executive: "Executive",
    },
    SERVICE_ERRORS: {
      ADD_ON: "Select services to add add-on.",
      BARRING: "Select services to update barring option.",
    },
  },
  CUSTOM_NUMBER: {
    SEARCH_TEXT: "Search custom phone numbers",
    COUNTDOWN_1: "You have ",
    COUNTDOWN_2: " minutes remaining to reserve your numbers.",
    MESSAGE_1: "You have selected ",
    MESSAGE_2:
      " plans. Search between 1 and 100 phone numbers, enter your preferred pattern details and assign custom numbers. There's 5 minutes to assign the available numbers or you'll have to search again.",
    ASSIGN_BUTTON: "Assign numbers",
    SEARCH_BUTTON: "Search numbers",
    PATTERN_TYPE_OPTIONS: [
      {
        label: "Starts With",
        value: "Begins",
      },
      {
        label: "Ends With",
        value: "Ends",
      },
      {
        label: "Contains",
        value: "Contains",
      },
    ],
    DEFAULT_PAGINATION_STATE: {
      data: [],
      pageSize: 10,
      totalRecordLength: 0,
      pages: 0,
      page: 0,
      recordsPerApi: 10,
    },
    QUANTITY_INVALID_INPUT: "Quantity must be greater than 0",
    PATTERN_INVALID_INPUT: "Pattern is required",
    PAGE_SIZE_OPTIONS: ["5", "10", "20", "25", "50", "100", "200"],
  },
};

export const SELECT_PLAN = {
  TABLE_VIEW: {
    QUANTITY: "Quantity",
    PLAN_TYPE: "Plan type - Plan",
    MONTHLY_CHARGES: "Monthly charges (ex GST)",
    DATA_ALLOWANCE: "Data allowance",
    SHARED_DATA_POOL: "Shared data pool",
    NETWORK: "Network",
    INCLUSIONS: "Inclusions",
  },
  FILTERS: {
    FILTER_MOBILE: "Mobile",
    FILTER_BROADBAND: "Broadband",
    FILTER_ENTERPRISE_WIRELESS: "Enterprise Wireless",
  },
  INCLUSIONS_LIST: {
    NORMAL: "Normal",
    READ_MORE: "Read more about plan inclusion",
  },
};

export const SELECT_PLAN_VIEWS = [
  {
    id: "table",
    label: "Table view",
    value: "table",
    active: false,
    disabled: false,
    icon: "icon-table-view",
  },
  {
    id: "list",
    label: "List view",
    value: "list",
    active: false,
    disabled: false,
    icon: "icon-system-list-view",
  },
  {
    id: "grid",
    label: "Grid view",
    value: "grid",
    active: false,
    disabled: false,
    icon: "icon-grid-view",
  },
];

export const BARRING_ADDONS_TABLE = {
  PLAN: "Plan",
  BARRING: "Barring",
  ADD_ONS: "Add-ons",
  CLONE: "Clone",
  REMOVE: "Remove",
  REMOVE_PLAN: {
    LABEL: "Remove Plan",
    CANCEL: "Cancel",
    TITLE: "Are you sure you want to remove this item?",
    DESCRIPTION: "This item will be permanently removed.",
  },
};

export const SIM_CONFIG_TABLE = {
  PLAN_TYPE: "Plan type - Plan",
  BARRING: "Barring",
  ADD_ONS: "Add-ons",
  SIM_TYPE: "SIM type",
  SIM_SERIAL_NUMBER: "SIM serial number",
  ADD_USERNAME: "Add username (optional)",
  SIM_SERIAL_NUMBER_13DIGIT_ERROR:
    "Invalid SIM number. A SIM number is 13 digits.",
  SIM_SERIAL_NUMBER_INVALID_UNMS: "Invalid SIM serial number.",
  ALL_SIM_SERIAL_NUMBER_VALIDATION:
    "All SIM serial numbers must be validated before adding to the cart. Please fix the following errors.",
  SIM_SERIAL_NUMBER_DUPLICATE:
    "Duplicate entry. Enter a unique SIM serial number.",
  INVALID_USERNAME:
    "Incorrect format. Use letters, numbers, apostrophes (') and hyphens (-) only.",
  ASSIGNED_PHONE_NUMBER: {
    HEADING: "Assigned phone number",
    TOOLTIP: {
      TITLE: "Refresh limited",
      DESCRIPTION:
        "You may only refresh to get a different phone number 2 times.",
    },
  },
};

export const ERROR_TYPES = {
  SIM_CONFIG_NUMBER: "SIM_CONFIG_NUMBER",
  SIM_CONFIG_NUMBER_PARTIAL_SUCCESS: "SIM_CONFIG_NUMBER_PARTIAL_SUCCESS",
  SIM_CONFIG_NUMBER_REFRESH: "SIM_CONFIG_NUMBER_REFRESH",
  SIM_CONFIG_NUMBER_SEARCH: "SIM_CONFIG_NUMBER_SEARCH",
  SIM_CONFIG_NUMBER_ASSIGNMENT: "SIM_CONFIG_NUMBER_ASSIGNMENT",
  SIM_CONFIG_PATCH_CART: "SIM_CONFIG_PATCH_CART",
};
